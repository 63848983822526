
  .secondary-button-text {
    font-size: 0.7rem !important;
    font-weight: 700;
  }
  
  .secondary-button {
    background-color: #ffffff;
    color: #1c2735;
    border-radius: 40px;
    border: 2px solid #1c2735;
    padding: 8px 14px;
    margin-right: 10px;
    cursor: pointer;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
  }
  
  .secondary-button:hover {
    background-color: #5a6268; 
  }
  
  @media screen and (min-width: 600px) {
    .secondary-button-text {
      font-size: 1rem !important;
      font-weight: 700;
    }
  }