html, body {
  overflow-x: hidden;
}
.body-container {
    background: #000000; 
    padding: 1vh;   
    padding-top: 7vh; 
    color: #ffffff !important;
  }
.sub-body {
    color: #ffffff;
    margin-top: 1vh;
}
 
.sub-body-2 {
    margin-bottom: 1vh;
}

.floating-button-container {
  position: fixed;
  top: 80%;
  transform: translate(0%, -30%);
  display: flex;
  flex-direction: row;
  background-color: #D9D9D9;
  border-radius: 10px;
}

.try-for-free {
 margin-top: 2vh
}

@media screen and (min-width: 600px) {
  .body-container {
    background: #000000; 
    padding: 1vh;   
    padding-top: 6vh; 
    color: #ffffff !important;
  }
}

@media screen and (min-width: 1100px) {
    .body-container {
      background: #000000; 
      padding: 2vh;   
      padding-top: 10vh; 
      color: #ffffff !important;
    }
    .sub-body {
      /* max-width: 800px;  */
      color: #ffffff;
      margin-top: 50px;
  }

  .floating-button-container {
    position: fixed;
    top: 87%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
    background-color: #D9D9D9;
    padding-top: 5px;
    padding-left: 10px;
    border-radius: 10px;
  }

  .try-for-free {
    margin-left: 10px;
    margin-top: 0;
  }
}

.sub-heading {
    font-size: 1.6rem;
    font-weight: 500;
    font-family:Arial, Helvetica, sans-serif;
}
.header-text{
    font-weight: 700;
    font-size: 4rem;
    font-family:Arial, Helvetica, sans-serif;
}

.highlighted {
    background-color: rgb(245, 230, 255, 0.3);
}

.highlighted-animation {
    animation: highlight 5s infinite linear;
    background: linear-gradient(to right,  #ff2400, #ff6600, #ffff00, #00ff00, #0000ff, #9400d3, #ff2400);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% auto;
    /* padding: 10px; */
  }
  
  @keyframes highlight {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }