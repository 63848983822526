.button-container {
    margin-top: 20px;
  }
  
  .primary-button {
    background-color: #1c2735; 
    border-radius: 40px;
    color: white;
    border: 2px solid #1c2735;
    padding: 8px 24px;
    margin-right: 10px;
    cursor: pointer;
    height: 52px;
  }

  .primary-button-text {
    font-size: 1.1rem !important;
    font-weight: 500;
  }
  
  