.usecase-section {
    border: 1px solid #ccc;
    background-color: #ffffff;
    color:#000000;
    border-radius: 10px;
    margin-bottom: 1vh;
    padding: 1vh;
    display:flex;
  }

.column-style {
  flex: 1;
}

.small-header-text{
  font-weight: 700;
  font-size: clamp(20px, 3vw + 1rem, 50px);
  font-family:Arial, Helvetica, sans-serif;
}

.description{
  font-weight: 700;
  font-size: clamp(10px, 1vw + 1rem, 30px);
  font-family:Arial, Helvetica, sans-serif;
}

.cloud-image {
  width: 30%;
  border-radius: 10px;
  margin-right: 2vw;
  margin-left: 2vw;
}

.right-image {
  width: 87vw; 
  border-radius: 10px;
  border: 2px solid #ccc;
}


@media screen and (min-width: 600px) {
  .right-image {
    width: 90vw; 
    border-radius: 10px;
    border: 2px solid #ccc;
  }
  .usecase-section {
    border: 1px solid #ccc;
    background-color: #ffffff;
    color:#000000;
    border-radius: 10px;
    margin-bottom: 1vh;
    padding: 1vh;
    display:flex;
  }
}

/* Styles for desktops */
@media screen and (min-width: 1100px) {
  .right-image {
    width: 60vw; 
    border-radius: 10px;
    border: 2px solid #ccc;
    margin: 1vh;
  }
  .usecase-section {
    border: 1px solid #ccc;
    background-color: #ffffff;
    color:#000000;
    border-radius: 10px;
    margin-bottom: 1vh;
    padding: 1vh;
    display:flex;
    min-height: 70vh;
  }
}

.deploy-images-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.left-column-center {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.delpoy-images-row {
  /* width: 60vw;  */
  display:flex;
  flex-direction: row;
  justify-content: center; 
  align-items: center;
  /* margin-top: 2vh;
  margin-bottom: 2vh; */
}