.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    background-color: #8d1627;
    color: white;
  }
  
  .logo-container {
    margin-right: 20px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
  }

  .logo {
    width: 20vw;
  }

  /* Styles for tablets */
@media screen and (min-width: 600px) {
  .logo {
    width: 22vw;
  }
}

/* Styles for desktops */
@media screen and (min-width: 1100px) {
  .logo {
    width: 15vw;
  }
}

  .header-buttons {
    display: flex; 
    flex-direction: row;
    margin-left: auto;
    margin-right: 2vh;
  }

  .navbar {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: row;
  }
  
  .navbar li {
    cursor: pointer;
    margin-right: 20px;
    font-weight: 600;
    font-size: 0.7rem;
    font-family: Arial, Helvetica, sans-serif;
  }

  @media screen and (min-width: 600px) {
    .navbar li {
      cursor: pointer;
      margin-right: 20px;
      font-weight: 600;
      font-size: 20px;
      font-family: Arial, Helvetica, sans-serif;
    }
  }