.modal {
    display: none; /* Hidden by default */
    position: fixed; 
    z-index: 900; 
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto; /* Enable scrolling if needed */
    background-color: rgba(0, 0, 0, 0.4); 
  }
  .modal.open {
    display: block; /* Show the modal when the 'open' class is applied */
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 6vh;
    padding-top: 2vh;
    padding-bottom: 10vh;
    border: 2px solid #000;
    border-radius: 10px;
    width: 65%
  }

  @media screen and (min-width: 1100px) {
    .modal-content {
      margin: 10% auto;
      padding: 50px;
      padding-top: 25px;
      padding-bottom: 80px;
      width: 30%;
    }
  }
  
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 200px;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  .send-contact-button {
    height: 32px;
    float: right;
    display: flex;
    align-items: center;
  }